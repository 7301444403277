export enum EntityType {
  EMAIL = 'EMAIL',
  MOBILE = 'MOBILE',
  USER_ID = 'USER_ID',
  MEMBER_ID = 'MEMBER_ID',
  FAMILY_MEMBER_ID = 'FAMILY_MEMBER_ID',
  ADMIN_ID = 'ADMIN_ID',
  PARTNER_ID = 'PARTNER_ID',
  DEVICE = 'DEVICE',
}
