<aside class="aside-navbar">
  <img
    class="sidebar-logo"
    src="assets/images/logo_white.png"
    width="36px"
    height="auto"
    alt=""
  />
  <ul class="nav-list">
    <li class="nav-item">
      <a [routerLink]="['/dashboard']" [routerLinkActive]="['nav-active']"
        ><span><i class="bi bi-grid-fill"></i></span
        ><ng-container i18n>Dashboard</ng-container></a
      >
    </li>
    <li *ngIf="role_data.indexOf(1) !== -1" class="nav-item">
      <a [routerLink]="['/customers']" [routerLinkActive]="['nav-active']"
        ><span><i class="bi bi-people-fill"></i></span
        ><ng-container i18n>manage customer</ng-container></a
      >
    </li>
    <li *ngIf="role_data.indexOf(2) !== -1" class="nav-item">
      <a [routerLink]="['/proposal']" [routerLinkActive]="['nav-active']"
        ><span><i class="bi bi-clipboard-data-fill"></i></span
        ><ng-container i18n>Gestion des affaires</ng-container></a
      >
    </li>
    <li *ngIf="role_data.indexOf(10) !== -1" class="nav-item">
      <a [routerLink]="['/category']" [routerLinkActive]="['nav-active']"
        ><span><i class="bi bi-list-task"></i></span
        ><ng-container i18n>Compagnies et produits</ng-container></a
      >
    </li>
    <li *ngIf="role_data.indexOf(12) !== -1" class="nav-item">
      <a [routerLink]="['/partners']" [routerLinkActive]="['nav-active']"
        ><span><i class="bi bi-person-video2"></i></span
        ><ng-container i18n>manage partner</ng-container></a
      >
    </li>
    <li *ngIf="role_data.indexOf(9) !== -1" class="nav-item">
      <a [routerLink]="['/agents']" [routerLinkActive]="['nav-active']"
        ><span><app-agent-network-icon width="16px" height="auto" /></span
        ><ng-container i18n>Agent Network</ng-container></a
      >
    </li>
    <li *ngIf="role_data.indexOf(13) !== -1" class="nav-item">
      <a
        [routerLink]="['/customer-executive']"
        [routerLinkActive]="['nav-active']"
        ><span><i class="bi bi-headset"></i></span
        ><ng-container i18n="@@custExecutive"
          >Customer Executive</ng-container
        ></a
      >
    </li>
    <li *ngIf="role_data.indexOf(11) !== -1" class="nav-item">
      <a [routerLink]="['/role-access']" [routerLinkActive]="['nav-active']"
        ><span><i class="bi bi-layers-fill"></i></span
        ><ng-container i18n>rules & access</ng-container></a
      >
    </li>
    <li *ngIf="role_data.indexOf(14) !== -1" class="nav-item">
      <a [routerLink]="['/referral']" [routerLinkActive]="['nav-active']"
        ><span><i class="bi bi-clipboard-data-fill"></i></span
        ><ng-container i18n>Gestion des parrainages</ng-container></a
      >
    </li>
    <li *ngIf="role_data.indexOf(4) !== -1" class="nav-item">
      <a [routerLink]="['/data-analysis']" [routerLinkActive]="['nav-active']"
        ><span><i class="bi bi-layers-fill"></i></span
        ><ng-container i18n>Data Analysis & Extraction</ng-container></a
      >
    </li>

    <li *ngIf="role_data.indexOf(3) !== -1" class="nav-item">
      <a
        [routerLink]="['/financial_statement']"
        [routerLinkActive]="['nav-active']"
        ><span><app-financial-statement-icon width="16px" height="auto" /></span
        ><ng-container i18n>financial statement</ng-container></a
      >
    </li>

    <li *ngIf="role_data.indexOf(15) !== -1" class="nav-item">
      <a
        [routerLink]="['/contract-templates']"
        [routerLinkActive]="['nav-active']"
        ><span> <app-letter-management-icon width="16px" height="auto" /> </span
        ><ng-container i18n>Modèles de contrat</ng-container></a
      >
    </li>
  </ul>
  <ul class="profile-nav mt-auto">
    <li
      class="nav-item"
      (click)="readNotifications()"
      data-bs-toggle="offcanvas"
      data-bs-target="#offcanvasRight"
      aria-controls="offcanvasRight"
    >
      <a
        ><span
          ><span class="bi bi-bell"
            ><sup *ngIf="totalNotification && totalNotification > 0">{{
              totalNotification
            }}</sup></span
          ></span
        ><ng-container i18n>Notifications</ng-container></a
      >
    </li>
    <li class="nav-item">
      <div class="dropend">
        <li
          class="nav-item dropdown-toggle"
          type="button"
          data-bs-toggle="dropdown"
          aria-expanded="false"
        >
          <a class="d-flex justify-content-between">
            <div class="d-flex align-items-center" id="profileDiv">
              <img
                width="32px"
                height="32px"
                *ngIf="auth?.profilePic != null"
                src="{{
                  auth?.profilePic
                    ? auth?.profilePic
                    : 'assets/images/userimg.png'
                }}"
                alt=""
              />
              <div id="initialsDiv" *ngIf="auth?.profilePic == null">
                {{ getInitials() }}
              </div>
              <ng-container>
                <div class="d-flex flex-column" id="infoDiv">
                  <span> {{ auth?.firstName }} {{ auth?.lastName }} </span>
                  <span id="emailSpan">{{ auth?.email }}</span>
                </div>
              </ng-container>
            </div>
            <app-double-chevron-icon heigth="16px" width="16px" />
          </a>
        </li>
        <ul class="dropdown-menu">
          <li>
            <a class="dropdown-item" [routerLink]="['dashboard/profile']"
              ><span><app-user-icon height="16px" width="auto" /></span
              ><ng-container i18n>Mon compte</ng-container>
            </a>
          </li>
          <li>
            <app-language-switcher></app-language-switcher>
          </li>
          <li>
            <hr class="dropdown-divider" />
          </li>
          <li>
            <a
              class="dropdown-item"
              href="javascript:void(0)"
              (click)="SignOut()"
              ><span><app-logout-icon height="16px" width="auto" /></span
              ><ng-container i18n>Se déconnecter</ng-container>
            </a>
          </li>
        </ul>
      </div>
    </li>
  </ul>
</aside>

<div
  class="offcanvas offcanvas-end notificaton-offcanvas"
  tabindex="-1"
  id="offcanvasRight"
  aria-labelledby="offcanvasRightLabel"
>
  <div class="offcanvas-header">
    <h5 id="offcanvasRightLabel" i18n>Notifications</h5>
    <button
      type="button"
      class="btn-close text-reset"
      data-bs-dismiss="offcanvas"
      aria-label="Close"
    ></button>
  </div>
  <div class="offcanvas-body">
    <ul>
      <li *ngFor="let notification of notificationList">
        <div class="d-flex align-items-center">
          <div class="notification-content">
            <p class="notification-title d-block mb-1">
              <strong>{{ notification.title }}</strong> {{ notification.body }}
            </p>
            <small>{{
              notification.createdOn | date: VDATE_TIME_FORMAT
            }}</small>
          </div>
        </div>
      </li>
    </ul>
  </div>
</div>
